import React, { FC } from 'react';

import classes from '../../../Dropdown/dropdown.module.scss';

interface IPreferencesDropdownIcon {
    username?: string;
}

const PreferencesDropdownIcon: FC<IPreferencesDropdownIcon> = ({ username = '' }) => {
    return (
        <>
            <span className={classes.label}>{username}</span>
            <span className={classes.d}>
                <span className="material-icons md-24" style={{ color: 'rgb(3, 161, 252)' }}>
                    person
                </span>
            </span>
            <span className={classes.a}>
                <span className={classes.on}></span>
            </span>
        </>
    );
};

export default PreferencesDropdownIcon;
