import React, { FC, MouseEvent, ReactElement, useState } from 'react';

import classes from './dropdown.module.scss';
import { IDropdownItem } from './DropdownItem';

interface IDropdown {
    title: ReactElement | string;
    menuTitle?: ReactElement | string;
    children?: ReactElement<IDropdownItem> | ReactElement<IDropdownItem>[];
}

const Dropdown = React.forwardRef<HTMLDivElement, IDropdown>(function Dropdown({ title, menuTitle, children }, ref) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleToggleMenu = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div ref={ref} className={`${classes['options-menu']} ${isMenuOpen ? classes.active : ''}`}>
            <a onClick={handleToggleMenu} className={classes.trigger}>
                {title}
            </a>
            <div className={classes.options}>
                {menuTitle}
                {children}
            </div>
        </div>
    );
});

export default Dropdown;
